<script lang="ts" setup>
import { useSelectSummaryItem, type ILeaseSummaryField } from '@register'
import dayjs from 'dayjs'
interface Props {
  field: ILeaseSummaryField
}
defineProps<Props>()

const { toggle, isSelected, isSelectable } = useSelectSummaryItem()
const emit = defineEmits(['close'])
const { dateFormat } = useCurrentLease()
</script>

<template>
  <div class="border-t-gray-750 space-y-2 border-t bg-white/20">
    <div class="sticky top-0 flex w-full bg-gray-600 p-2">
      <span class="text-primary flex-grow text-sm">
        {{ capitalize(field.name) }} history:
      </span>
      <Button
        color="transparentPrimary"
        class="group h-6 w-6 px-0"
        icon="close"
        @click="emit('close')"
      >
      </Button>
    </div>

    <div v-if="field.history?.before?.length">
      <ul class="divide-gray-650 divide-y">
        <li>
          <div class="flex flex-row p-2 text-sm">
            <span class="w-[90px] text-xs text-gray-400"> Effective date </span>
            <div class="text-xs text-gray-400">Value</div>
          </div>
        </li>
        <li v-for="value in field.history.before" :key="value.value">
          <!-- Non Button -->

          <VTooltip
            v-if="!isSelectable(field, value)"
            class="flex flex-row p-2 text-sm"
            :delay="10"
            placement="top"
            :distance="12"
            :auto-hide="true"
          >
            <span class="w-[90px] text-xs text-gray-400">
              <time>
                {{ dayjs(value?.from).format(dateFormat) }}
              </time>
            </span>
            <div>
              <RegisterSummaryFieldValue :field="field" :value="value" />
            </div>
            <template #popper>
              <div class="text-xs">Changed</div>
            </template>
          </VTooltip>

          <VTooltip
            v-else
            class="flex flex-row p-2 text-sm"
            :delay="10"
            placement="top"
            :distance="12"
            :auto-hide="true"
          >
            <div
              class="flex w-full flex-row p-2 text-sm"
              role="button"
              :tabindex="0"
              :class="[
                'hover:before:bg-primary/30 cursor-pointer',
                isSelected(field, value) && 'before:!bg-primary',
              ]"
              @click="
                isSelectable(field, value) ? toggle(field, value) : undefined
              "
              @keyup.enter="
                isSelectable(field, value) ? toggle(field, value) : undefined
              "
            >
              <span class="w-[90px] text-xs text-gray-400">
                <time>
                  {{ dayjs(value?.from).format(dateFormat) }}
                </time>
              </span>
              <div>
                <RegisterSummaryFieldValue :field="field" :value="value" />
              </div>
            </div>
            <template #popper>
              <div class="text-xs">Changed</div>
            </template>
          </VTooltip>
        </li>
      </ul>
    </div>
  </div>
</template>
