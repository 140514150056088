import { storeToRefs } from 'pinia'
import { useLeaseReviewWebViewer } from '@register/components/Review/composables'
import type { ILeaseSummaryField, ILeaseSummaryFieldValue } from '@register'
import { useSummaryStore } from '@register'

export interface UseSelectSummaryItemOptions {
  persist?: boolean
}

export const useSelectSummaryItem = (
  options: UseSelectSummaryItemOptions = {},
) => {
  const { persist = false } = options
  const queryField = useRouteQuery<string | undefined>('field')

  const { fields, selectedField, selectedFieldValue } =
    storeToRefs(useSummaryStore())
  const { changeDocument: _changeDocument } = useLeaseReviewWebViewer()
  const { add: addAnnotations, remove: removeAnnotations } = useAnnotations()

  const isSelected = (
    field: ILeaseSummaryField,
    value?: ILeaseSummaryFieldValue,
  ) => {
    return (
      selectedField.value === field &&
      selectedFieldValue.value === (value ?? field.value)
    )
  }

  const isSelectable = (
    field: ILeaseSummaryField,
    value?: ILeaseSummaryFieldValue,
  ) => {
    const _value = value ?? field.value
    return hasBounds(_value?.bounds)
  }

  const select = (
    field: ILeaseSummaryField,
    value?: ILeaseSummaryFieldValue,
  ) => {
    if (!isSelectable(field, value) || isSelected(field, value)) return

    // Change document
    const documentId = field.value?.documentId
    if (documentId) {
      _changeDocument(documentId)

      const _value = value ?? field.value

      // Go to annotation
      if (_value && hasBounds(_value?.bounds)) {
        selectedField.value = field
        selectedFieldValue.value = _value

        addAnnotations(documentId, _value?.bounds)
      }
    }

    if (persist) {
      queryField.value = field.id
    }
  }

  const unselect = () => {
    selectedField.value = undefined
    selectedFieldValue.value = undefined
    removeAnnotations()

    if (persist) {
      queryField.value = undefined
    }
  }

  const toggle = (
    field: ILeaseSummaryField,
    value?: ILeaseSummaryFieldValue,
  ) => {
    if (isSelected(field, value)) {
      unselect()
      return
    }

    select(field, value)
  }

  if (persist) {
    watch(
      queryField,
      (field) => {
        if (!selectedField.value) {
          const _field = fields.value.find((f) => f.id === field)
          if (_field) {
            select(_field)
          }
        }
      },
      { immediate: true },
    )
  }

  return { select, unselect, toggle, isSelected, isSelectable }

  function hasBounds<T extends any[]>(
    bounds: T | null | undefined,
  ): bounds is T {
    return (!!bounds && bounds.length > 0) as boolean
  }
}
